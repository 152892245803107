.contactCustom input,.form-control{
    width: 100% !important;
} 

.registration-wrapper{
    background-image: url(../../../../assets/images/banner/registrationbanner.png);
    /* height: 100vh; */
    margin-top: -64px; /* compensating the extra margins comming from layout */
    background-color: #173649;
    background-repeat: no-repeat;
    background-size: contain;
}
.registration-wrapper .registration-row-main{
    /* padding: 200px 50px; */
    padding-top: 200px;
    justify-content: center;
    margin: 0px 50px !important;
    padding-bottom: 85px;
}
.registration-wrapper .ant-form-item-explain{
    min-height: 10px;
}
.registration-wrapper .react-tel-input .form-control{
    height: 40.12px;
}
.registration-wrapper .ant-form-item {
    margin-bottom: 10px;
}
.registration-wrapper .ant-form-item-row{
    display: unset;
    margin-bottom: 10px !important;
}
.registration-wrapper .registration-row-main .registration-form-left .ant-form-vertical .ant-form-item .ant-form-item-label > label{ 
    color: rgb(255, 255, 255) !important;
}

.registration-wrapper .registration-form-right .login-now{
    text-align: center;
    border: 2px solid #bf9c15;
    padding: 15px;
    border-radius: 10px;
    color: white;
    font-size: 22px;
    width: 500px;
    margin: auto;
    margin-top: 60px;
}
.registration-wrapper .registration-form-right p{
    font-size: 33px;
    line-height: 46px;
    color: white;
}
.registration-wrapper .registration-form-right .login-now span{
    color: #C98C12;
    font-weight: 700; 
}
.registration-wrapper .registration-form-right .reg-fig .fig{
    height: 400px;
    margin: auto auto;
    display: grid;
    text-align: center;
    padding-left: 55px;
    padding-top: 15px;
}
.registration-wrapper .registration-form-right .reg-fig ul{
    /* text-align: center; */
    margin-top: 35px;
    font-size: 25px;
    color: white;
}
.registration-wrapper .registration-form-right .reg-fig ul li{
    margin-bottom: 30px;
    display: flex;
    gap: 10px;
    align-items: center;
}
.registration-wrapper .registration-form-right .reg-fig ul li .check{
    /* margin-bottom: 30px; */
    height: 35px;
}

.registration-wrapper  .login-form-button {
    width: 40%;
    height: 50px;
    font-size: 18px;
    margin-top: 15px;
    border-radius: 8px; 
    background-color: #F3BC03; /* Yellow background */
    border-color: #F3BC03; /* Match border color */
    color: white; /* Text color */
}
  
.registration-wrapper .login-form-button:hover {
    background-color: #e0a800; /* Darker yellow on hover */
    border-color: #e0a800; /* Darker yellow border on hover */
    color: white; /* Text color */
}

.registration-wrapper .login-form-button:focus {
    background-color: #e0a800; /* Darker yellow on focus */
    border-color: #e0a800; /* Darker yellow border on focus */
    color: white; /* Text color */
}

.registration-wrapper .ant-form-item-explain-error {
    color: #ff4d4f;  /* Red error message color */
    font-weight: bold;  /* Make the error message bold */
    margin: 5px 0px;
  }

  .registration-wrapper .registration-form-left .ant-result-title,
.registration-wrapper .registration-form-left .ant-result-subtitle {
    color: white; 
    font-size: 20px;
}
  .registration-wrapper .registration-form-left .success-banner{
    background-color: #000000ab;
    border-radius: 28px;
}

.registration-wrapper .registration-form-left .faq-logo{
    position: absolute;
    bottom: 9px;
    right: 6px;
    height: 75px
}

@media (max-width: 550px) {
    .registration-wrapper .registration-row-main{
        margin: 0px 0px !important;
    }
    .registration-wrapper .registration-form-right .login-now{
        font-size: 22px;
        width: unset;
    }
    .registration-wrapper  .login-form-button {
        width: 100%;
        margin-bottom: 15px; 
    }
    .registration-wrapper .registration-form-right .reg-fig .fig{
        height: 200px;
        padding-left: unset;
    }
    .registration-wrapper .registration-form-right .reg-fig ul{
        /* text-align: center; */
        margin-top: 35px;
        font-size: 19px;
        color: white;
    }
    .registration-wrapper .registration-form-left .faq-logo{
        top: 0px;
    }
}