.footer-main{
    margin-top: 70px;
    margin-bottom: 60px;
}
.footer-main span{
    color: #16364b;
    font-size: 22px;
    font-weight: 600;
    display: flex;
}
.footer-main .social-icons-footer{
    display: flex;
    gap: 8px;
    margin: 30px 0px;
}
.footer-main .social-icons-footer a{
    border: 1px solid #35607C;
    padding: 7px;
    border-radius: 100%;
    box-sizing: border-box;
}
.footer-main .social-icons-footer a img{
   height: 30px;
   transition: 0.2s ease-in-out;

}
.footer-main .social-icons-footer a img:hover{
   scale: 1.1;

}
.footer-main .social-icons-footer span{
    display: flex;
    gap: 8px;
    flex-direction: row;
    border: 1px solid black;
    height: 33px;
    width: 33px;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
}
.footer-main input{
    margin-top: 30px !important;
}
.footer-main .btn-dark{
    margin-top: 5px;
    background-color: black;
    color: white;
}

.footer-main .contact-info .contact{
    margin-top: 30px;
}
.footer-main .contact-info .contact span ,.mail span{
    font-weight: unset;
    font-size: unset;
}
.footer-main .contact-info .contact,
.mail{
    display: flex;
    gap: 9px;
    align-items: center;
}

.footer-main .contact-info p:first-child{
    margin-top: 30px;
}
.footer-main .contact-info p{
    margin: 0;
    color:#16364b
}
.footer-main .asso{
    margin-top: 30px;
}
.footer-main .asso img{
    height:25px
}

@media only screen and (max-width: 768px) {
   .footer-bottom .footer-logo img{
    height: 50px;
    width: 50px;
    margin: auto auto;
   }
   .footer-main .col-md-3{
    margin-top: 30px;
   }
   .footer-main .contact-info .contact{
    margin-top: 20px;
   }
  }
  .disclaimer{
    margin-top: 10px;
    line-height: 22px !important;
    font-weight: unset;
    font-size: 13px;
    padding: 0px 20px;
  }

  .full-disclosure-btn{
    background: unset !important;
    color:#F3BC03 !important;
    text-decoration: underline;
    padding: unset !important;
  }
  .full-disclosure-btn:hover{
    color: #f0d92d !important;
    padding: unset !important;
  }