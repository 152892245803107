.side_nav{
    .ant-menu{
        background-color: #F1F6FB;

        .ant-menu-item{
            color:#14395E;
            font-weight: 500;
        }
        .admin-role{
            background-color: #14395E;
            color: white;
            padding: 20px 0px;
            border-radius: 15px;
        }
    }
    ul{
        padding: 0px 5px;
    }
}