.login-wrapper{
    /* background-color: blue; */
    background-image: url('../../../assets/images/banner/loginbanner.png');
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: -64px; /* compensating the extra margins comming from layout */
    min-height: 100vh;
}
.login-wrapper .login-wrapper-row{
    margin: 0px 58px !important;
    height: inherit !important;
    padding-top: 15%;
    /* height: 100vh !important; */
}
.login-wrapper .login-form-wrapper{
    color: white;
    background-color: #102f227d;
    padding: 50px 60px;
    border-radius: 50px;
    border: 1px solid rgb(255, 187, 0);
}
.login-wrapper .login-form-wrapper .ant-form-item-explain-error {
    color: #ff4d4f;  /* Red error message color */
    font-weight: bold;  /* Make the error message bold */
    margin: 5px 0px;
  }

.login-wrapper .login-form-wrapper .passwor-input{
    margin: 15px 0px !important;
}
.login-wrapper .login-form-wrapper .login-form-forgot{
    float: right;
    color: white;
}
.login-wrapper .login-form-wrapper .register-now{
    color: white;
    display: inline-block;
    margin-top: 15px;
   
}
.login-wrapper .login-form-wrapper a{
    color: white;
    font-weight: bold;
}
.login-wrapper .login-form-wrapper a:hover{
    color: rgb(220, 215, 215);
}
.login-wrapper .login-form-wrapper .rounded-input {
    border-radius: 8px; 
}
.login-wrapper .login-form-wrapper .login-form-button {
    width: 100%;
    margin-top: 15px;
    border-radius: 8px; 
    background-color: #F3BC03; /* Yellow background */
    border-color: #F3BC03; /* Match border color */
    color: white; /* Text color */
}
  
.login-wrapper .login-form-wrapper .login-form-button:hover {
background-color: #e0a800; /* Darker yellow on hover */
border-color: #e0a800; /* Darker yellow border on hover */
}

.login-wrapper .login-form-wrapper .login-form-button:focus {
background-color: #e0a800; /* Darker yellow on focus */
border-color: #e0a800; /* Darker yellow border on focus */
}

.login-wrapper .login-left-section{
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Align content to the top */
    height: 50%;     
}
.login-wrapper .head-text{
    font-size: 23px;    
    color: white; 
    margin-bottom: 8px;
    display: inline-block;
}
.login-wrapper .sub-text{
    font-size: 30px;    
    color: #F3BC03; 
}

@media (max-width: 550px) {
    .login-wrapper{

    }
    .login-wrapper .login-form-wrapper{
        padding: 50px 20px;
    }
    .login-wrapper .login-wrapper-row{
        margin: 0px 10px !important;
        gap: 25px;
        padding: 250px 0px !important;
    }
}