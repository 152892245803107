.faq-container {
    
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .faq-title {
    text-align: center;
    color: #333;
  }
  
  .faq-collapse {
    margin: 0 150px;
    border: none;
  }
  
  .faq-panel {
    margin-bottom: 10px;
  }
  
  .faq-panel .ant-collapse-header {
    background-color: #e6f7ff;
    color: #007bff;
    font-weight: bold;
  }
  
  .faq-panel .ant-collapse-content {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 15px;
  }
  
  @media only screen and (max-width: 768px) {
    .faq-collapse {
        margin: 0 10px;
      }
    }