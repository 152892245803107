#login {
  display: none;
}
#register {
  display: none;
}
@media only screen and (max-width: 768px) {
  #login {
    display: block;
  }
  #register {
    display: block;
  }
  .logo-area-wrapper{
    align-items: center;
    justify-content: center;
    display: flex;
  }
}

.new-col {
  padding:15px
}
.danger-btn {
  background: linear-gradient(90deg, #386fd0 0%, #2fbaca 100%);
  border-radius: 40px;
  margin-right: 10px;
  padding: 10px;
  font-family: Poppins;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.05em; 
  text-transform: uppercase;
  color: #fff;
  width: 126px;
  height: 39px;
}


.primary-bttn,.register-bttn {
  /* border: 2px solid #e6a210; */
  border-radius: 40px;
  margin-right: 10px;
  padding: 9px;
  font-family: Poppins;
  font-weight:600;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  width: 126px;
  height: 39px;
  color:white;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}
.bttn-primary{
  background:rgb(0, 121, 228) 
}
.bttn-warning{
  background: #EEC217;
}
.bttn-warning:hover{
  background: #dbb312;
  color: white;
}
.register-bttn:hover{
  background: rgb(4, 103, 190) ;
  color: white;
}
.primary-bttn img{
  height: 16px;
}
.register-bttn img{
  height: 16px;
}

.primary-btn:hover {
  background: linear-gradient(90deg, #386fd0 0%, #2fbaca 100%);
  color: #fff;
}

.logo-area a{
  display: flex;
  gap: 10px;
  align-items: center;
  color: white;
  font-weight: 600;
  font-size: 17px;
}

.nav_header_wrapper{
  background: #000000b0;
  display: flex;
  margin-top: -1px;
}

.navs_list{
  display: flex;
  justify-content: end;
  align-items: center;
}
