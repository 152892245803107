
.image-col {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .section-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .content-card {
    height: 100%;
  }
  h3.ant-typography{
    color: #022146;
  }
  .ant-typography strong{
    color: #022146;
  }
  h4.ant-typography{
    color: #022146;
  }
  
  .text-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  /* .text{
    margin-top: 9rem;
  } */
  div.ant-typography{
     margin-bottom: unset !important; 
}
  
  @media (max-width: 768px) {
    .reverse-on-mobile {
      flex-direction: column-reverse;
    }
    
    .disclaimer-container {
      padding: 1rem;
    }
    
    .content-section {
      margin-bottom: 1rem;
    }
    
    .content-card {
      margin-bottom: 12px;
    }
  }
  .disclaimer-container {
    padding: 2rem;
    max-width: 1499px;
    margin: 0 auto;
    background-color: #ffffff;
  }
   
  .content-section {
    margin-bottom: 1rem;
  }
  
  .content-card {
    height: 100%;
    /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05); */
    /* margin-bottom: 2rem; */
  }
  
  .ant-typography + .ant-typography {
    margin-top: 1rem;
  }
  
  .section-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .mt-4 {
    margin-top: 2rem;
  }
  

  