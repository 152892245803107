
#components-layout-demo-top-side-2{
  .horizontal-dd{
    background-color: white;

    .ant-menu-item{
      color: black;
    }
    .ant-menu-submenu{
      color: #102253;
      font-weight: 500;

      & .ant-menu-submenu-title:hover{
        color: #1c2d5a;
      }
    }
  }
  
  .logo {
    min-width: 120px;
    height: 31px;
    // background: rgba(255, 255, 255, 0.2); 
    margin: 16px 28px 16px 0;
    float: left;
    display: flex;
    align-items: center;
  
    img{
      height: 50px;
    }
  
    #title {
      text-align: center;
      color: #14395E;
      // margin-top: -2px;
      margin: 0px;
      font-size: 20px;
  
    }
  }
}

.ant-menu-submenu .ant-menu{
  background-color: #F1F6FB !important;
  color: #14395E !important;
  font-weight: 500;

}



.blockchain-dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 4px;;
}

.green-blockchain-dot {
  @extend .blockchain-dot;
  background-color: rgb(50, 224, 44);
}

.red-blockchain-dot {
  @extend .blockchain-dot;
  background-color: red;
}