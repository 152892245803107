.ant-card .my-tokens-card {
    width: 100%; /* Make the card occupy full width */
    background-color: #FFFDF6 !important;
    border-radius: 10px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

     & .ant-tabs-tab {
        background-color: #EEC21766;
        border-radius: 10px;
        margin-right: 8px;
        padding: 8px 10px;
      }
     & .token-details {
        margin-top: 20px;
        width: 100%;
        background: unset;
      }
     & .ant-col {
        font-size: 14px;
        color: #233a5e;
      }

     & .ant-tabs-tab-active {
        background-color: #14395E;
        
        border-radius: 8px;

        & .ant-tabs-tab-btn{
            color: white;
        }
      }
     & .ant-tabs-tab:hover{
        background-color: #14395E;
        & .ant-tabs-tab-btn{
            color: white;
        }
     }
    & .ant-tabs-nav::before {
        border-bottom: none;
      }
    & .ant-tabs-ink-bar {
        display: none;
      }
  }

  .custom-table-container {
    background-color: #e3eaf2;
    // padding: 20px;
    border-radius: 10px;
    width: 100%;
    padding: 0;

      & .table-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        padding: 0px 20px;
      }
    
      & .ant-table {
        border-radius: 10px;
      }
      
      & .ant-table-thead > tr > th {
        background-color: #FFFDF6 !important;
        color: #6c7582;
      }
      
      & .ant-table-tbody > tr > td {
        font-size: 16px;
      }
      
      & .ant-table-tbody > tr.even-row {
        background-color: #f2f7ff;
      }
      
      & .ant-table-tbody > tr.odd-row {
        background-color: #FFFDF6;
      }
      
      & .ant-table-tbody > tr:hover {
        background-color: #e1eaff !important;
      }
      
      & .ant-table-cell {
        text-align: left;
        padding: 16px;
        vertical-align: middle;
      }
    
      & .even-row {
        background-color: #f2f7ff;
      }
      
      & .odd-row {
        background-color: #f9f9f9;
      }
    }


  
  
  .view-all-btn {
    border: 1px solid #000 !important;
    border-radius: 20px !important;
    font-weight: bold;
    font-size: 14px !important;
    color: #233a5e;
    background-color: #FFFDF6 !important;
  }
  h2 {
    font-size: 24px;
    font-weight: bold;
  }
  
  .custom-table-container-transaction{
  .transaction-btn{
    background-color: #F9EBB9 !important;
    color: #EEC217;
    border: 1px solid #EEC217;
    border-radius: 15px;
    // font-size: 19px;
    padding: 8  12px;
    height: unset;
  }
  
  & .ant-table-tbody > tr.even-row {
    background-color: #f2f7ff;
  }
  
  & .ant-table-tbody > tr.odd-row {
    background-color: #FFFDF6;
  }
  
  & .ant-table-tbody > tr:hover {
    background-color: #e1eaff !important;
  }

  & .add-token{
    background-color: #FFFFFF !important;
    color: #14395E;
    border: 1px solid #14395E;
    border-radius: 20px;
    padding: 9px 29px;
    height: unset;
    font-weight: 600;

    &:hover{
      color: #14395E;
    }
  }

  & .table-header{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
}
  
  

  